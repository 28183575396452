<template>
  <div>
    <div class="home"
         style="
        position: relative;
        height: calc(100vh - 150px);
        min-width: 1024px;
        overflow: auto;
        min-height:600px
      ">
      <div class="login_btn">
        <img v-if="styleBackImg"
             :src="styleBackImg"
             alt />
        <img v-else
             src="../assets/logo_btn.png"
             alt />
      </div>
      <!-- <div class="logo_buttom">
        <img src="../assets/logo_buttom.png" alt />
      </div>-->

      <div style="
          width: 400px;
          background: #fff;
          padding: 42px;
          top: 160px;
          right: 440px;
          position: absolute;
        ">
        <div style="margin-bottom:10px;font-weight:700;color:#333;text-align:center">{{ name }}</div>
        <el-tabs v-model="activeName"
                 type="border-card"
                 @tab-click="handleClick">
          <el-tab-pane name="first">
            <span slot="label">用户登录</span>
            <div style="margin: 10px 0">
              <el-form ref="passForm"
                       :rules="passRules"
                       status-icon
                       :model="passForm">
                <el-form-item prop="username">
                  <el-input type="text"
                            placeholder="请输入登录名"
                            v-model="passForm.username"
                            autocomplete="off"
                            clearable></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input type="password"
                            placeholder="请输入密码"
                            v-model="passForm.password"
                            autocomplete="off"
                            clearable
                            @keyup.enter.native="submitForm('passForm', 'pass')"></el-input>
                </el-form-item>
              </el-form>
              <div>
                <el-button style="width: 100%; background: #11659a"
                           type="primary"
                           @click="submitForm('passForm', 'pass')">登录</el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

        <div style="text-align: right">
          <el-button type="text"
                     v-prev
                     path="/forget">忘记密码?</el-button>
        </div>
      </div>
    </div>
    <Footer :styleAddress="styleAddress"
            :stylePhone="stylePhone"></Footer>
    <el-dialog title="修改密码"
               :visible.sync="dialogFormVisible">
      <el-form :model="form"
               ref="ruleForm"
               :rules="modifyForm">
        <el-form-item label="新密码"
                      :label-width="formLabelWidth"
                      prop="password">
          <el-input type="password"
                    placeholder="请输入6-20位包含数字,字母的新密码"
                    v-model="form.password"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码"
                      :label-width="formLabelWidth"
                      prop="checkPass">
          <el-input type="password"
                    placeholder="请输入确认密码，并保持两次密码一致"
                    v-model="form.checkPass"
                    autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="goSubmit('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { userLogin, userSendLoginCode } from '../api';
import { api } from '../api/base/index';
export const customerSetting = api()('customer.setting.json');
export const customerPasswdFirst = api()('customer.passwd.first.json');
import Top from '../components/top.vue'
import Footer from '../components/footer.vue'

export default {
  components: {
    [Top.name]: Top,
    [Footer.name]: Footer
  },
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录名'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      const reg = /^[\w_-]{6,16}$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!reg.test(value)) {
        callback(new Error('密码必须为6-16位数字或者字母组成'));
      } else {
        callback();
      }
    };
    var validateSmsCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      }
      setTimeout(() => {
        if (value.length !== 6) {
          callback(new Error('请输入6位验证码'));
        } else {
          callback();
        }
      }, 1000);
    };

    var validateNewPass = (rule, value, callback) => {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (!reg.test(value)) {
        callback(new Error('密码必须为6-16位数字或者字母组成'));
      } else {
        callback();
      }
    };
    var validateNewCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'));
      } else if (this.form.password !== value) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {
      activeName: 'first',
      stylePhone: '',
      styleAddress: '',
      styleBackImg: '',
      styleLogo: '',
      name: '',
      input: '',
      passForm: {
        username: '',
        password: '',
      },
      passRules: {
        username: [{ validator: validatePhone, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
      },
      smsForm: {
        username: '',
        smsCode: '',
      },
      smsRules: {
        username: [{ validator: validatePhone, trigger: 'blur' }],
        smsCode: [{ validator: validateSmsCode, trigger: 'blur' }],
      },
      seconds: 60,
      isShowSconds: false,
      config: {},
      form: {
        password: '',
        checkPass: ''
      },
      modifyForm: {
        password: [{ required: true, validator: validateNewPass, trigger: 'blur' }],
        checkPass: [{ required: true, validator: validateNewCheckPass, trigger: 'blur' }],
      },
      dialogFormVisible: false,
      formLabelWidth: '120px',

    };
  },
  created () {
    this.loadDate()
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    },
    async loadDate () {
      const params = {
        customerCode: 'kw5r1ij50'
      };
      const res = await customerSetting(params);
      this.stylePhone = res.stylePhone
      this.name = res.name
      this.styleAddress = res.styleAddress
      this.styleBackImg = res.styleBackImg
      this.styleLogo = res.styleLogo
      window.localStorage.setItem('syleLogo', res.styleLogo)
      window.localStorage.setItem('enterprise', res.enterpriseCode)
      localStorage.setItem('enterpriseCode', res.enterpriseCode);
      localStorage.setItem('enterpriseName', res.name);
      // window.localStorage.setItem('enterpriseName', res.name)

    },
    async getSmsCode () {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!this.smsForm.username) {
        return this.$message.error('请输入手机号码');
      }
      if (this.smsForm.username && !phoneReg.test(this.smsForm.username)) {
        return this.$message.error('请输入正确的手机号码');
      }
      await userSendLoginCode({
        phone: this.smsForm.username,
      });
      this.$message.success('验证码发送成功');
      this.isShowSconds = true;
      this.seconds--;
      const _interval = setInterval(() => {
        this.seconds--;
        if (this.seconds === 0) {
          clearInterval(_interval);
          this.isShowSconds = false;
          this.seconds = 60;
        }
      }, 1000);
    },
    async submitForm (formName, type) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (type === 'pass') {
            const res = await userLogin(this.passForm);
            const _item = Object.assign({}, res);
            delete _item.enterprise
            if (res.isEnterpriseSuperAdmin) {
              Object.keys(res).forEach((it) => {
                window.localStorage.setItem(it, res[it]);
              });
            }
            else {
              Object.keys(_item).forEach((its) => {
                window.localStorage.setItem(its, _item[its]);
              });
            }
            if (!res.changedPwd && res.roles === 'r2' && res.isEnterpriseSuperAdmin) {
              this.dialogFormVisible = true
            }
            else {
              this.$message.success('成功登录');
              window.location.replace(
                this.$route.query.from || '/web/system/index/home',
              );
            }
          }
          if (type === 'sms') {
            return this.$message.info('开发中...');
            // const res = await userLoginSms(this.smsForm);
            // Object.keys(res).forEach(it => {
            //   window.localStorage.setItem(it, res[it]);
            // });
          }


        } else {
          return false;
        }
      });
    },

    goSubmit (form) {
      if (this.form.password === this.form.checkPass) {
        this.$refs[form].validate(async valid => {
          if (valid) {
            await customerPasswdFirst({
              password: this.form.password
            })
            window.localStorage.clear()
            this.$message({
              message: '修改成功,请重新登录',
              type: 'success'
            });
            this.dialogFormVisible = false;
          }
        })
      }
    },
  },
};
</script>
<style>
.el-divider.line {
  background-color: #fff;
}
</style>
<style lang="scss" scoped>
.el-tabs__nav {
  display: flex;
  width: 100%;
}
.el-tabs__item {
  flex-grow: 1;
}
.el-tabs__nav > .el-tabs__item:last-child {
  border-right: none;
}
.el-form-item {
  margin-bottom: 25px;
}
.el-tabs--border-card {
  border: 1px solid #dcdfe6;
}
.top-left {
  width: 136px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
  }
}
.top-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #131824;
  width: 50%;
}
.login_btn {
  width: 100%;
  height: calc(100vh - 150px);
  img {
    width: 100%;
    height: 100%;
  }
}
.logo_buttom {
  width: 100%;
  display: flex;
  align-items: unset;
  justify-content: center;
  margin: 100px 0 20px 0;
  img {
    width: 1300px;
  }
}
</style>
